@import '../Button/VariableColors.module.scss';

.secondaryButton {
  min-width: 9.2rem;
  cursor: pointer;
  width: 'fit-content';
  height: 2.8rem;
  border-radius: 0.4rem;
  background: $primary-color;
  border: 0.1rem solid #313248;
  color: #fffeff;

  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}
