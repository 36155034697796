.body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #191a23;
  .container {
    width: 33.6rem;
    display: flex;
    justify-content: center;
    .header {
      display: flex;
      justify-content: center;
      margin-bottom: 3.2rem;
    }
    .loginDetails {
      width: 100%;
      .inputLabel {
        margin-bottom: 0.8rem;
        color: #d2d3e0;
        font-size: 1.3rem;
        span {
          color: #d2d3e0;

          font-size: 2rem;
          font-style: normal;
          font-weight: 600;
          line-height: 2.8rem;
        }
      }
      .phoneInput {
        margin-bottom: 1.6rem;
      }
    }
    .verifyDetails {
      .headerText {
        margin-bottom: 1.4rem;
      }
      span {
        color: #d2d3e0;

        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
      }
    }
    .wrongNumAndOtp {
      display: flex;
      justify-content: space-between;

      .resendOtp {
        display: flex;
        justify-content: space-around;
        color: #007f7c;
        cursor: pointer;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        margin-top: 0.8rem;
        margin-bottom: 0.9rem;
      }
      .otpBtn {
        display: flex;
        justify-content: center;
        color: #007f7c;
        cursor: pointer;
        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        margin-bottom: 1.6rem;
        margin-top: 0.8rem;
        margin-left: 0.8rem;
      }
    }
  }
}
