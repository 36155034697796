.phoneInput {
  .react-tel-input .country-list {
    background-color: red;
    border: 1px solid #ccc;
  }
  .input {
    width: 33.6rem;
    height: 3.8rem;
    border-radius: 0.4rem;
    border: 0.1rem solid #212234;
    background: #151621;
    color: #d2d3e0;

    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    .input .country-list {
      background-color: red;
    }
    &[disabled] {
      background-color: #3c3c45;
      color: #4f505e;
      cursor: not-allowed;
    }
  }
  .phoneCtn:focus {
    border: 0.1rem solid #212234;
    box-shadow: none;
  }
  .countryBtn:focus {
    border: 0.1rem solid #212234;
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 0.1rem;
    border-right: none;
  }

  .input:hover {
    border: 0.1rem solid #212234;
    box-shadow: none;
  }

  .input:focus {
    border: 0.1rem solid #212234;
    box-shadow: none;
  }

  .countryBtn:focus,
  .countryBtn:active {
    border: none;
  }

  .countryBtn:focus {
    background-color: transparent;
  }

  .dropdown {
    height: 13rem;
    border-radius: 0.5rem;
    width: 33.6rem;
    // background-color: #151621;
    // color: #d2d3e0;
    // border: 0.05rem solid #2c2d3c;
  }
  .dropdown::-webkit-scrollbar {
    display: none;
  }
}
