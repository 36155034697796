.container {
  width: 36.5rem;
  // @media (max-width: 1024px) {
  //   width: 32rem;
  // }
  height: 95vh;
  box-sizing: border-box;
  border: 0.1px solid #212234;
  background: #191a23;
  position: absolute;
  top: 5.6rem;

  right: -37rem;
  // @media (max-height: 1000px) {
  //   height: 93.8vh;
  // }
  // @media (max-height: 900px) {
  //   height: 90.5vh;
  // }
  // @media (min-height: 1000px) and (max-height: 2000px) {
  //   height: 95vh;
  // }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    height: 7.6rem;
    border-bottom: 0.1rem solid #212234;
    margin-bottom: 2.4rem;
    span {
      color: #d2d3e0;

      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.6rem;
    }
    .crossSvg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 0.4rem;
      background: #20212e;
      cursor: pointer;
    }
  }
  .formContainer {
    box-sizing: border-box;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    .formDetails {
      // width: 100%;
      // display: flex;
      // gap: 0.8rem;

      .startTimeContainer {
        display: flex;
        gap: 0.9rem;
        margin-bottom: 1rem;
        .startTime {
          width: 25rem;
        }
        .startMeridiem {
          margin-top: 2.2rem;
        }
      }
      .endTimeContainer {
        display: flex;
        gap: 0.9rem;
        .endMeridiem {
          margin-top: 2.7rem;
        }
      }
    }
    .bookingLimit {
      margin-top: 2rem;
    }
    .buttonCtn {
      display: flex;
      flex-direction: row;
      gap: 1.6rem;
      margin-top: 3rem;
    }
  }
}
