.content {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0rem;
    height: 0rem;
  }
}
.titleText {
  font-weight: 400;
  font-size: 16px;
  line-height: 2.2rem;
  margin-bottom: 1.2rem;
}
.editContainer {
  background-color: #20212e;
  padding: 2rem 2.4rem 2rem 3rem;
  width: 100%;
  border-radius: 0.8rem;
  margin-bottom: 3.2rem;
}

.activeContainer {
  display: flex;
  justify-content: end;
  .isActiveContainer {
    display: flex;
    gap: 0.8rem;
    .isActive {
      margin-top: 0.6rem;
    }
  }
}

.rowOne {
  display: flex;
  gap: 2rem;
}

.rowTwo {
  display: flex;
  gap: 2rem;

  .stateCase {
    text-transform: uppercase;
  }

  .stateCase::placeholder {
    text-transform: none;
  }

  .stateCase.ant-input::placeholder {
    text-transform: none;
  }
}

.rowThree {
  display: flex;
  gap: 2rem;
}
.rowFour {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;

  .customUpload :global(.ant-upload-select) {
    display: block;
  }
  .customUpload :global(.ant-upload-list-item-name) {
    font-size: 12px;
    margin-right: 3rem;
  }
  .customUpload :global(.ant-upload-list-item) {
    width: fit-content;
    max-width: 250px;
  }
  /* Styles for delete (trash bin) icon */
  .customUpload :global(.ant-upload-list-item .anticon-delete) {
    color: #d2d3e0 !important;
    font-size: 14px !important;
    cursor: pointer;
  }

  .customUpload :global(.ant-upload-list-item .anticon-delete:hover) {
    color: #d2d3e0 !important; /* Change color on hover */
  }

  .customUpload :global(.ant-upload-list-item .anticon-paper-clip) {
    color: #d2d3e0 !important;
    cursor: pointer;
  }
  .customUpload :global(.ant-upload-list-item .anticon-paper-clip:hover) {
    color: #d2d3e0 !important;
  }
}

.buttonContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
  margin-top: 2rem;

  .footer {
    display: flex;
    gap: 0.8rem;
    .button {
      height: 2.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 9.2rem;
    }
  }
}
.bedSlotContainer {
  .toggle {
    margin-bottom: 1.2rem;
  }
}
.wrapper {
  .activeContainer {
    display: flex;
    justify-content: end;
    .isActiveContainer {
      display: flex;
      gap: 0.8rem;
      .isActive {
        margin-top: 0.6rem;
      }
    }
  }
  .timePicker {
    display: flex;
    width: 317;
    justify-content: space-evenly;
  }
  div {
    .footer {
      display: flex;
      flex-direction: row;
      gap: 0.8rem;
      margin-top: 1.6rem;
      .button {
        height: 2.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 9.2rem;
      }
    }
  }
}

.closeContainer {
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 4px;
  background: #20212e;
  background-image: url(../../../../images/cross.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.scheduleContainer {
  background-color: #20212e;
  padding: 1.2rem 2rem;
  width: 100%;
  overflow-x: scroll;
  height: 100%;
  border-radius: 0.8rem;

  .editScheduleBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    position: sticky;
    left: 50%;
  }
  .availabilityRow {
    display: flex;
    gap: 1.2rem;
    .daysWrapper {
      display: flex;
      margin-top: 0.5rem;
      // @media (max-width: 770px) {
      //   margin-top: 1.44rem;
      // }
      justify-content: center;
      align-items: center;
      width: 10rem;
      height: 3.8rem;
      border-radius: 4px;
      border: 1px solid #2d2c3c;
      .daysHeader {
        color: #d2d3e0;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
    .availabilityDays {
      .availabilitySet {
        gap: 1.7rem;

        .timeDropdown {
          display: flex;
          margin-top: 0.5rem;
          // @media (max-width: 1080px) {
          //   margin-top: 1rem;
          // }
          gap: 1rem;
          .timeDropdownContainer {
            display: flex;
            gap: 0.8rem;
            // @media (max-width: 1080px) {
            //   flex-direction: column;
            // }
            .startTimeContainer {
              display: flex;
              min-width: 20rem;
            }
            .endTimeContainer {
              display: flex;
              min-width: 20rem;
            }
            .tableAndFrequency {
              display: flex;
              gap: 1rem;
              // @media (max-width: 1080px) {
              //   flex-direction: column;
              // }
            }
          }
        }
      }
    }
    .addBtnParent {
      margin-top: 0.4rem;
    }
    .radioToggleButtonParent {
      margin-top: 0.2rem;
    }
  }
}

.availabilityBtnContainer {
  width: 100%;
  margin-top: 3rem;
  .footer {
    display: flex;
    gap: 0.8rem;
    .button {
      height: 2.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 9.2rem;
    }
  }
}
