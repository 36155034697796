.container {
  .label {
    color: #d2d3e0;

    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;
  }

  .displayLabelNone {
    display: none;
  }
  .displayLabel {
    margin-bottom: 0.4rem;
  }

  .textField {
    width: 31.7rem;
    height: 3.8rem;
    box-sizing: border-box;
    // color: #858699;
    padding-left: 1.6rem;
    border-radius: 0.4rem;
    border: 0.01rem solid #2c2d3c;
    background-color: transparent;
    text-transform: capitalize;
  }

  ::placeholder {
    color: #858699;

    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;
  }
}
