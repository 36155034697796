.initialTableComponent {
  // margin: 6% 3% 6% 3%;
  margin: 4rem 2rem 0 1.5rem;
  width: 80vw;
  height: 22rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  border: 0.05rem solid #2c2d3c;
  background: #20212e;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1);
  .initialComponent {
    text-align: center;

    .waveSign {
      margin-top: -2rem;
    }

    .textContent {
      margin-top: 2rem;
      color: #d2d3e0;

      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }

    .button {
      margin-top: 2rem;
    }
  }
}
