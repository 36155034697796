// @import '../Button/VariableColors.module.scss';
$primary-color: #007f7c;
$secondary-color: #272939;
$tertiary-color: #2c2d3c;

.navButton {
  width: 19.3rem;
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row-reverse;
  height: 2.7rem;
  border-radius: 0.4rem;
  background: $primary-color;
  border: 0.1rem solid #313248;
  color: #fffeff;

  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  .icon {
    position: relative;
    margin-left: 0.3rem;
    margin-right: 1rem;
  }

  // @media (max-width: 1024px) {
  //   .textContent {
  //     display: none;
  //   }
  // }
}
