$charade: #282a37;
$bluebell: #979fd0;

.loading {
  display: flex;
  width: 78vw;
  height: 62vh;
  justify-content: center;
  align-items: center;
  background-color: #20212e;

  div {
    width: 1.2rem;
    height: 1.2rem;
    margin: 2rem 0.3rem;
    background: #007f7c;
    border-radius: 50%;
    animation: 0.7s bounce infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes bounce {
  to {
    opacity: 0.3;
    transform: translate3d(0, -1rem, 0);
  }
}
