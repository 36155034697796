.body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #191a23;
  .container {
    width: 33.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .header {
      display: flex;
      justify-content: center;
      margin-bottom: 3.2rem;
      flex-direction: column;
      .loginAsText {
        color: #d2d3e0;

        font-size: 2.4rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.8rem;
      }
    }
    .tabs {
      display: flex;
      gap: 2rem;
      margin-bottom: 3rem;
    }
  }
}

.LogoContainer {
  width: 100%;
  height: 27px;
  margin-top: 12px;
  margin-left: 15px;
  margin-bottom: 12px;
  .logo {
    height: 24px;
    width: 24px;
  }
  @media (max-width: 1199px) {
    margin-left: 27px;
  }
}

.SignoutContainer {
  display: flex;
  position: absolute;
  bottom: 20px;
  padding: 20px;
  color: white;
  cursor: pointer;
  @media (max-width: 1199px) {
    margin-left: 1rem;
  }
  span {
    margin-left: 10px;
    @media (max-width: 1199px) {
      display: none;
    }
  }
}
