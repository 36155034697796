@font-face {
  font-family: 'WixMadeforDisplay';
  src: url('../assets/fonts/WixMadeforDisplay/WixMadeforDisplay-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'WixMadeforDisplay';
  src: url('../assets/fonts/WixMadeforDisplay/WixMadeforDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: 'WixMadeforDisplay';
  src: url('../assets/fonts/WixMadeforDisplay/WixMadeforDisplay-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'WixMadeforDisplay';
  src: url('../assets/fonts/WixMadeforDisplay/WixMadeforDisplay-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'WixMadeforDisplay';
  src: url('../assets/fonts/WixMadeforDisplay/WixMadeforDisplay-Medium.ttf') format('Medium');
  font-weight: 400;
}

// // Helvetica Nueue - only for lofi
// @font-face {
//   font-family: 'HelveticaNeue';
//   src: url('../assets/fonts/HelveticaNeue/HelveticaNeue-01.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'HelveticaNeue';
//   src: url('../assets/fonts/HelveticaNeue/HelveticaNeue-Italic-03.ttf') format('truetype');
//   font-style: italic;
// }

// @font-face {
//   font-family: 'HelveticaNeue';
//   src: url('../assets/fonts/HelveticaNeue/HelveticaNeue-Light-08.ttf') format('truetype');
//   font-weight: 300;
// }

// @font-face {
//   font-family: 'HelveticaNeue';
//   src: url('../assets/fonts/HelveticaNeue/HelveticaNeue-LightItalic-09.ttf') format('truetype');
//   font-weight: 300;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'HelveticaNeue';
//   src: url('../assets/fonts/HelveticaNeue/HelveticaNeue-Medium-11.ttf') format('truetype');
//   font-weight: 500;
// }

// @font-face {
//   font-family: 'HelveticaNeue';
//   src: url('../assets/fonts/HelveticaNeue/HelveticaNeue-MediumItalic-12.ttf') format('truetype');
//   font-weight: 500;
//   font-style: italic;
// }

// // Noto Sans - hifi designs
// @font-face {
//   font-family: 'NotoSans';
//   src: url('../assets/fonts/NotoSans/NotoSans-Regular.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'NotoSans';
//   src: url('../assets/fonts/NotoSans/NotoSans-Italic.ttf') format('truetype');
//   font-style: italic;
// }

// @font-face {
//   font-family: 'NotoSans';
//   src: url('../assets/fonts/NotoSans/NotoSans-Medium.ttf') format('truetype');
//   font-weight: 500;
// }

// @font-face {
//   font-family: 'NotoSans';
//   src: url('../assets/fonts/NotoSans/NotoSans-MediumItalic.ttf') format('truetype');
//   font-style: italic;
//   font-weight: 500;
// }

// @font-face {
//   font-family: 'NotoSans';
//   src: url('../assets/fonts/NotoSans/NotoSans-SemiBold.ttf') format('truetype');
//   font-weight: 600;
// }

// @font-face {
//   font-family: 'NotoSans';
//   src: url('../assets/fonts/NotoSans/NotoSans-SemiBoldItalic.ttf') format('truetype');
//   font-style: italic;
//   font-weight: 600;
// }

// @font-face {
//   font-family: 'NotoSans';
//   src: url('../assets/fonts/NotoSans/NotoSans-Bold.ttf') format('truetype');
//   font-weight: 700;
// }

// @font-face {
//   font-family: 'NotoSans';
//   src: url('../assets/fonts/NotoSans/NotoSans-BoldItalic.ttf') format('truetype');
//   font-style: italic;
//   font-weight: 700;
// }

// // Poppins - hifi designs
// @font-face {
//   font-family: 'Poppins';
//   src: url('../assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
//   font-weight: 300;
// }

// @font-face {
//   font-family: 'Poppins';
//   src: url('../assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Poppins';
//   src: url('../assets/fonts/Poppins/Poppins-Italic.ttf') format('truetype');
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Poppins';
//   src: url('../assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
//   font-weight: 600;
// }

// @font-face {
//   font-family: 'Poppins';
//   src: url('../assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
//   font-style: italic;
//   font-weight: 600;
// }

// @font-face {
//   font-family: 'Poppins';
//   src: url('../assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
//   font-weight: 700;
// }

// @font-face {
//   font-family: 'Poppins';
//   src: url('../assets/fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
//   font-style: italic;
//   font-weight: 700;
// }

// @font-face {
//   font-family: 'Trispace';
//   src: url('../assets/fonts/Trispace/Trispace-Regular.ttf') format('truetype');
//   font-weight: 400;
// }

// @font-face {
//   font-family: 'Trispace';
//   src: url('../assets/fonts/Trispace/Trispace-Medium.ttf') format('truetype');
//   font-weight: 500;
// }
