.container {
  width: 80vw;
  height: 30rem;
  box-sizing: border-box;
  border: 0.1px solid #212234;
  background: #20212e;

  margin: 0.8rem 2.4rem 0 1.5rem;
  border-radius: 0.8rem;
  // .header {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   box-sizing: border-box;
  //   padding-left: 2.4rem;
  //   padding-right: 2.4rem;
  //   height: 7.6rem;
  //   border-bottom: 0.1rem solid #212234;
  //   margin-bottom: 2.4rem;
  //   span {
  //     color: #d2d3e0;
  //
  //     font-size: 1.8rem;
  //     font-style: normal;
  //     font-weight: 400;
  //     line-height: 2.6rem;
  //   }
  //   // .crossSvg {
  //   //   display: flex;
  //   //   justify-content: center;
  //   //   align-items: center;
  //   //   width: 3.2rem;
  //   //   height: 3.2rem;
  //   //   border-radius: 0.4rem;
  //   //   background: #20212e;
  //   // }
  // }
  .formContainer {
    box-sizing: border-box;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    .formDetails {
      width: 100%;
      display: flex;
      gap: 2rem;
      padding-top: 1.3rem;
      .flexColumnOne {
        width: 38vw;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
      .flexColumnTwo {
        width: 38vw;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
    .buttonCtn {
      display: flex;
      justify-content: flex-end;
      gap: 1.2rem;
      margin-top: 2.1rem;
      // padding-right: 0.8rem;
      margin-right: 0.8rem;
    }
  }
}
