.supportContainer {
  .headerContainer {
    display: flex;
    gap: 1rem;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    .searchBar {
      margin-top: 20px;
      margin-left: 5rem;
      width: 485px;
      height: 40px;
      font-size: 14px;
      background-color: #191a23;
      border: 1px solid #2c2d3c;
      border-radius: 4px;
    }
  }

  .appointmentCards {
    height: 80vh;
    padding-bottom: 1rem;
    overflow: scroll;
    .cardList {
      padding-left: 5rem;
      display: flex;
      margin-top: 12px;
      flex-direction: column;
      gap: 0.8rem;
      width: 40%;
      .profileContainer {
        display: flex;
        align-items: center;
        padding-left: 1rem;
        min-width: 700px;
        min-height: 5.8rem;
        border-radius: 4px;
        border: 1px solid #2c2d3c;
        background: #20212e;
        color: #d2d3e0;
        cursor: pointer;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        @media (max-width: 830px) {
          display: block;
          min-width: 350px;
          justify-content: start;
          align-items: start;
          border-right: none;
        }

        .profileLeftDiv {
          width: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 3.4rem;
          padding-left: 1rem;
          border-right: 0.15rem solid #2c2d3c;
          @media (max-width: 830px) {
            margin-top: 10px;
            height: 34px;
            justify-content: start;
            align-items: start;
            border-right: none;
          }
          .profileImg {
            margin-right: 0.8rem;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            line-height: 10px;
            align-items: center;
            justify-content: center;
            display: flex;
          }
          .profileImgTxt {
            margin-right: 0.8rem;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            color: black;
            font-weight: 600;
            background-color: #ababb2;
            line-height: 10px;
            align-items: center;
            justify-content: center;
            display: flex;
          }
          .profileName {
            min-width: 18rem;
            margin-right: 2rem;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 2rem;
            text-transform: capitalize;
          }
          .phone {
            color: #d2d3e0;
            min-width: 6rem;
            margin-right: 2rem;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }
        @media (max-width: 830px) {
          div:last-child {
            margin-top: -0.5rem;
          }
        }
        :last-child {
          border-right: none;
        }
      }
    }
  }
}
