.datePickerContainer {
  height: 70px;
  background-color: #20212e;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-left: 30px;
  margin-right: 24px;
  margin-top: 14px;
  border-radius: 4px;

  .startDatePicker {
    margin-left: 14px;
    width: 174px;
  }

  .selectStudio {
    width: 174px;
    height: 32px;
    background-color: #191a23;
    border-radius: 4px;
  }

  .endDatePicker {
    width: 174px;
  }

  .applyBtn {
    height: 30px;
  }
}

.summaryTitle {
  color: #d2d3e0;
  font-size: 13px;
  font-weight: 700;
  margin-left: 11px;
  margin-bottom: 16px;
}

.summaryContainer {
  // display: flex;
  gap: 14px;
  margin-left: 11px;

  // @media (min-width: 1000px) and (max-width: 1200px) {
  //   flex-direction: column;
  // }

  .summaryCard {
    background-color: #20212e;
    border: 1px solid #2c2d3c;
    // min-height: 20%;
    min-width: 23.8%;
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

    .sumContainer {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      padding-left: 14px;
      padding-right: 14px;

      .titleColumn {
        min-width: 23%;
        .reportTitle {
          margin-top: 14px;
        }
        .reportTitle:last-child {
          margin-bottom: 14px;
        }
      }
      .amountColumn {
        min-width: 11%;
        margin-right: -0.2rem;
        .reportValue {
          margin-top: 14px;
          display: flex;
          flex-direction: row-reverse;
        }
        .reportValue:last-child {
          margin-bottom: 14px;
        }
      }
    }

    .summaryDetails {
      display: flex;
      justify-content: space-between;
      color: #d2d3e0;
      font-size: 13px;
      min-width: 10%;
      margin-left: 14px;
      // margin-right: 74px;
      margin-bottom: 15px;
      margin-top: 15px;

      .reportValue {
        min-width: 22%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        text-align: center;
      }
    }
  }
}
