.timeInputContainer {
  display: flex;
  border-radius: 4px;
  //   border: 1px solid #2c2d3c;
  background: #20212e;

  .selectTimeDropdown {
    width: 11rem;
  }

  .selectAmPmDropdown {
    width: 9rem;
  }
}
