.dropdownMenu {
  background-color: #191a23;
  width: 20rem;
  text-transform: capitalize;
  border: '0.5px solid #2C2D3C';
  &[disabled] {
    // background-color: red;
    color: green;
  }
}
.dropdownsss {
  text-transform: capitalize;
}
.label {
  color: #d2d3e0;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.2rem;
  // text-transform: capitalize;
}
.displayLabel {
  margin-bottom: 0.4rem;
}
.displayLabelNone {
  display: none;
}
// .dropdownsss {
//   background-color: red;
// }
