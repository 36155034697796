.wrapper {
  div {
    .dropdownMenu {
      background-color: #191a23;
      width: 20rem;
      text-transform: capitalize;
      border: '0.5px solid #2C2D3C';
    }
    .dropdownsss {
      text-transform: capitalize;
    }
    .footer {
      display: flex;
      flex-direction: row;
      gap: 0.8rem;
      margin-top: 1.6rem;
      .button {
        height: 2.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 9.2rem;
      }
    }
    .stateCase {
      text-transform: uppercase;
    }

    .stateCase::placeholder {
      text-transform: none;
    }

    .stateCase.ant-input::placeholder {
      text-transform: none;
    }
    .customUpload :global(.ant-upload-list-item-name) {
      font-size: 12px;
      margin-right: 3rem;
    }
    .customUpload :global(.ant-upload-list-item) {
      width: fit-content;
      max-width: 250px;
    }

    /* Styles for delete (trash bin) icon */
    .customUpload :global(.ant-upload-list-item .anticon-delete) {
      color: #d2d3e0 !important;
      font-size: 14px !important;
      cursor: pointer;
    }

    .customUpload :global(.ant-upload-list-item .anticon-delete:hover) {
      color: #d2d3e0 !important; /* Change color on hover */
    }

    .customUpload :global(.ant-upload-list-item .anticon-paper-clip) {
      color: #d2d3e0 !important;
      cursor: pointer;
    }
    .customUpload :global(.ant-upload-list-item .anticon-paper-clip:hover) {
      color: #d2d3e0 !important;
    }
    // .customUpload :global(.ant-upload-list-item .anticon-paper-clip svg:hover) {
    //   fill: blue !important;
    // }
  }
}
.closeContainer {
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 4px;
  background: #20212e;
  background-image: url(../../../images/cross.svg);
  background-repeat: no-repeat;
  background-position: center;
}
