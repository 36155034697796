.initialComponentContainer {
  .initialComponentHeading {
    color: #d2d3e0;

    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.2rem;
    padding-top: 2.8rem;
    padding-left: 4rem;
  }
}

.screenerDiv {
  height: 90vh;
  width: 83vw;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 5rem;
  &::-webkit-scrollbar {
    width: 0rem;
    height: 0rem;
  }
  .screenerAvailability {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 3.5rem;
    padding-right: 2rem;
    width: 85vw;
    @media (max-width: 1024px) {
      width: 80vw;
    }

    .screenerAvailabilityHeader {
      color: #d2d3e0;
      font-weight: 700;
      font-size: 1.6rem;
      font-style: normal;
      line-height: 2.2rem;
      padding-left: 0.8rem;
      padding-bottom: 0.8rem;
    }

    .availabilityOverallContainer {
      background: #20212e;
      width: 80vw;
      height: fit-content;

      @media (max-width: 1024px) {
        width: 90vw;
      }

      border-radius: 8px;
      border: 1px solid #2c2d3c;

      .availabilityContainer {
        padding-left: 2.2rem;
        padding-top: 2.2rem;
        padding-bottom: 2.2rem;

        .availabilityRow {
          //   position: relative;
          // margin-top: 1rem;
          display: flex;
          // @media (max-width: 650px) {
          //   flex-direction: column;
          // }
          gap: 1.2rem;
          .daysDiv {
            display: flex;
            margin-top: 0.4rem;
            @media (max-width: 770px) {
              margin-top: 1.44rem;
            }
            justify-content: center;
            align-items: center;
            width: 10.7rem;
            height: 3.5rem;
            border-radius: 4px;
            border: 1px solid #2d2c3c;
            .daysHeader {
              color: #d2d3e0;

              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }
          }
          .availabilityDays {
            .availabilitySet {
              gap: 1.7rem;

              .addBtn {
              }
              .deleteBtnParent {
                margin-top: 0.5rem;
              }
              .timeDropdown {
                // width: 20rem;
                display: flex;
                @media (max-width: 770px) {
                  margin-top: 1rem;
                  // flex-direction: column;
                }
                gap: 1rem;
                .timeDropdownContainer {
                  display: flex;
                  gap: 2rem;
                  @media (max-width: 770px) {
                    flex-direction: column;
                  }
                }
              }
              .isActive {
                padding-left: 0.8rem;
                color: #858699;

                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
              }
              .radioToggleButtonParent {
                margin-top: 0.5rem;
              }
            }
          }

          .isActive {
            width: 7rem;
            color: #858699;

            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
          .addBtnParent {
            margin-top: 0.5rem;
          }

          .radioToggleButtonParent {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }

  .buttonCtn {
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
    margin-top: 1rem;
    margin-left: 3.5rem;
    @media (max-height: 600px) {
      margin-top: 1.5rem;
    }
  }
}
