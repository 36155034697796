.container {
  height: 95vh;
  margin-top: 2rem;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0rem;
    height: 0rem;
  }
  padding-left: 2.4rem;
  .clinicTitle {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding-left: 1.5rem;
  }
  .segmentContainer {
    margin-top: 2rem;
    padding-left: 1.5rem;
  }
  .bedAndSlot {
    margin-top: 2rem;
  }
}
