.radioLabel {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.6rem;
}

.radioBtn {
  opacity: 0;
  width: 0;
  height: 0;
}

.radioSpan {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s;
  cursor: pointer;
  background: #858699;
  border-radius: 30px;
}
.radioSpan:before {
  position: absolute;
  content: '';
  height: 1.2rem;
  width: 1.2rem;
  left: 3px;
  bottom: 0.2rem;
  background-color: #d2d3e0;
  border-radius: 50%;
  transition: 0.3s;
}

.radioBtn:checked + span {
  background-color: #007f7c;
}

.radioBtn:checked + span:before {
  transform: translateX(1.2rem);
}

// .radioBtn:checked + .radioSpan {
//     background-color: #00c853;

//     &:before {
//       transform: translateX(29px);
//     }
//   }
