// body {
//   overflow-x: hidden;
//   transform: translateZ(0);
//   overflow-y: hidden;
// }
// html {
//   overflow-x: hidden;
//   overflow-y: hidden;
// }

html::-webkit-scrollbar {
  display: none;
}

// body::-webkit-scrollbar {
//   display: none;
// }

// .BodyContainer {
//   display: flex;
//   background-color: #191a23;
//   .Sidebar {
//     box-sizing: border-box;
//     width: 15.3vw;
//     height: 100vh;
//     // border-right: 0.1rem solid #2d2c3c;
//     @media (max-width: 1025px) {
//       width: 4rem;
//     }
//     .container {
//       width: 13.5vw;
//       height: 100%;
//       padding: 1.2rem 1.3rem 0 1.2rem;
//       background: #191a23;
//       .panelBox {
//         width: 100%;
//         height: 100%;
//         .header {
//           display: flex;
//           align-items: center;
//           width: 100%;
//           // height: 2.7rem;
//           padding-left: 0.6rem;
//           margin-bottom: 1.2rem;
//           .logo {
//             height: 22px;
//             width: 22px;
//           }
//         }
//         .panelContent {
//           display: flex;
//           flex-direction: column;
//           gap: 0.8rem;
//         }
//       }
//     }
//   }

//   .tabContainer {
//     border-bottom: 0.05rem solid #212234;
//     height: 56px;
//     display: flex;
//     align-items: center;
//     .tabTrack {
//       height: 24px;
//       border-radius: 8px;
//       background: #20212e;
//       display: flex;
//       margin-left: 30px;

//       .tabItemActive {
//         width: 119px;
//         border-radius: 8px;
//         border: 1px solid #2c2d3c;
//         background: #272939;
//         box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         color: #d2d3e0;

//         font-size: 13px;
//         font-style: normal;
//         font-weight: 400;
//         line-height: 16px; /* 123.077% */
//         cursor: pointer;
//       }
//       .tabItem {
//         color: #858699;

//         font-size: 13px;
//         font-style: normal;
//         font-weight: 400;
//         line-height: 16px; /* 123.077% */
//         width: 119px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         cursor: pointer;
//       }
//     }
//   }
//   // .comingSoonDiv {
//   //   padding-top: 3rem;
//   //   padding-bottom: 3rem;
//   //   padding-left: 2.4rem;

//   //   .overallContainer {
//   //     background-color: #20212e;
//   //     width: 80vw;
//   //     height: 70vh;

//   //     border-radius: 0.8rem;
//   //     display: flex;
//   //     align-items: center;
//   //     justify-content: center;
//   //     .textContent {
//   //       color: #d2d3e0;

//   //       font-size: 20px;
//   //       font-style: normal;
//   //       font-weight: 400;
//   //       line-height: 28px;
//   //     }
//   //   }
//   // }
// } // .BodyContainer {
//   display: grid;
//   grid-template-columns: 20vw 80vw;
//   grid-template-rows: 10vh 90vh;
//   grid-template-areas:
//     'Sidebar Header'
//     'Sidebar PageContent';
//   height: 100vh; // Assuming you want the BodyContainer to take up the full height
// }
// .Sidebar {
//   grid-area: Sidebar;
//   background-color: green;
// }
// .Header {
//   grid-area: Header;
//   background-color: blue;
// }
// .PageContent {
//   grid-area: PageContent;
//   background-color: yellow;
// }

.Container {
  width: 100%;
  height: 100%;
  //background-color: white;
  //padding-bottom: 50px;
  // overflow: scroll;
  display: flex;
  flex-direction: column;
  .tabContainer {
    border-bottom: 0.05rem solid #212234;
    height: 56px;
    display: flex;
    align-items: center;
    position: sticky;
    .tabTrack {
      height: 40px;
      border-radius: 4px;
      background: #20212e;
      display: flex;
      margin-left: 30px;

      .tabItemActive {
        width: 129px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #2c2d3c;
        background: #272939;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #d2d3e0;

        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 123.077% */
        cursor: pointer;
      }
      .tabItem {
        color: #858699;
        width: 129px;
        height: 40px;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 123.077% */

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
  .AppointmentListContainer {
    // background-color: wheat;
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 0rem;
      height: 0rem;
    }
    width: 100%;
    flex: 1;
    padding-bottom: 50px;
    .profileList {
      // background-color: yellow;
      padding-left: 30px;
      // height: 100%;
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      gap: 0.8rem;
      width: 65.7rem;

      //  overflow: scroll;
      .allProfiles {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
      }
      .dayDiv {
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 31px;
        border-radius: 4px;
        border: 1px solid #2c2d3c;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        margin-bottom: 1.2rem;
        color: #d2d3e0;

        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px; /* 115.385% */
      }
    }
  }
}
