.patientDetails {
  margin-bottom: 5rem;
  .patientDetailsHeading {
    color: #d2d3e0;
    margin-top: 2rem;
    margin-left: 3rem;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .patientProfileContainer {
    display: flex;
    width: 96%;
    justify-content: space-between;
    margin-left: 3rem;
    margin-right: 2.4rem;
    margin-top: 1rem;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .patientProfile {
      display: flex;
      margin-top: 0.8rem;
      .patientProfileImg {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.5rem;
        height: 70px;
        width: 70px;
        border-radius: 0.8rem;
        background-color: #ababb2;
        .initialLetters {
          font-size: 3rem;
        }
      }
      .patientInfo {
        .patientName {
          color: #d2d3e0;
          text-transform: capitalize;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 700;
          line-height: 1.6rem;
          margin-top: 1.3rem;
          &:not(:first-child) {
            margin-top: 0.8rem;
          }
        }
        .patientAgeGender {
          color: #d2d3e0;
          text-transform: capitalize;
          font-size: 1.3rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.6rem;
          margin-top: 1.3rem;
          &:not(:first-child) {
            margin-top: 0.8rem;
          }
        }
      }
    }
    .actionButtonsContainer {
      @media (max-width: 768px) {
        margin-top: 2rem;
      }
      .actionsContainer {
        display: flex;
        gap: 1.2rem;
        margin-top: 1rem;
      }
      .dateIdentifier {
        // width: 100%;
        width: 17.7rem;
        height: 3.2rem;
        margin-top: 1.6rem;
        border-radius: 4px;
        border: 1px solid #2c2d3c;
        background: #20212e;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
  }
  .patientDetailsContent {
    overflow-y: scroll;
    // height: 90vh;
    height: calc(100vh - 15.6rem);
    padding-bottom: 2rem;
    width: 99%;

    &::-webkit-scrollbar {
      width: 0rem;
      height: 0rem;
    }

    .pastHealthConditionTitle {
      margin-left: 3rem;
      margin-top: 3rem;
      margin-bottom: 0.8rem;
      color: #d2d3e0;

      font-size: 1.3rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2.2rem;
    }
    .healthConditionsContainer {
      width: 96.7%;
      margin-left: 3rem;
      margin-right: 2rem;
      // display: grid;
      // grid-template-columns: auto auto 1fr;
      // grid-column-gap: 1.3rem;
      // grid-row-gap: 0.8rem;
    }
    // .feedbackHeading {
    //   margin-left: 3rem;
    //   margin-top: 3rem;
    //   margin-bottom: 0.8rem;
    //   color: #d2d3e0;
    //
    //   font-size: 1.3rem;
    //   font-style: normal;
    //   font-weight: 400;
    //   line-height: 2.2rem;
    // }
    // .feedbackField {
    //   margin: 3rem 3rem;
    //   width: 80vw;
    //   height: 22rem;
    //   border-radius: 4px;
    //   border: 1px solid #2c2d3c;
    //   background: #20212e;
    //   box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    // }
    .feedbackTitle {
      color: #d2d3e0;
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 1.5rem;
      padding-left: 3.1rem;
      padding-top: 3rem;
    }
    .feedbackTextArea {
      padding: 0px;

      max-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      background: #20212e;
      box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1);
    }
    .feedbackArea {
      @media (max-width: 992px) {
        width: 94%;
      }

      background: #20212e;
      border: 0.05rem solid #2c2d3c;
      top: 0;
      min-height: 18rem;
      border-radius: 0.8rem;
      width: 96.6%;
      margin: 1rem 3rem;
    }

    .feedbackUploadContainer {
      margin: 3rem 3rem;
      width: 96.6%;
      @media (max-width: 992px) {
        width: 94%;
      }
      min-height: 22rem;
      max-height: 100%;
      display: flex;
      // justify-content: center;
      // align-items: center;
      border-radius: 0.8rem;
      border: 0.05rem solid #2c2d3c;
      background: #20212e;
      box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1);

      .feedbackContainer {
        display: flex;
        flex: 1;
        gap: 3rem;
        // justify-content: center;
        // flex-direction: column;
        // text-align: center;
        // padding-bottom: 0.5rem;
        .uploadBar {
          width: 32.1rem;
          height: 100%;
          border-radius: 0.8rem;
          // border: 1px dashed #2c2d3c;
        }
        .loadingContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 30px;
          padding-top: 1.2rem;
          padding-bottom: 1.2rem;
          flex: 1;
          border-radius: 0.8rem;
          border: 1px solid #2d2c3c;
          background: #212231;
          padding-top: 1.2rem;
          padding-bottom: 1.2rem;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        }
        .uploadedFilesContainer {
          display: flex;
          flex: 1;
          border-radius: 0.8rem;
          padding-left: 30px;
          border: 1px solid #2d2c3c;
          background: #212231;
          padding-top: 1.2rem;
          padding-bottom: 1.2rem;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
          .uploadedFiles {
            width: 241px;

            .uploadText {
              color: #d2d3e0;

              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px; /* 123.077% */
              margin-bottom: 0.8rem;
            }
            .fileListBar {
              display: flex;
              align-items: center;
              padding-left: 0.8rem;
              border-radius: 0.4rem;
              width: 100%;
              height: 3.2rem;
              border: 1px solid #2d2c3c;
              background: #212231;
              color: #d2d3e0;

              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: 10px; /* 100% */
              margin-bottom: 0.4rem;
            }
          }
        }

        .cloudUploadSign {
          // display: flex;
          // justify-content: center;
          // flex-direction: column;
          // align-items: center;
          // margin-top: -2rem;
          // margin-left: 13.5rem;
          // margin-left: 6rem;
          // width: 2.4rem;
          // height: 2.4rem;
        }
        .dragText {
          color: #d2d3e0;

          font-size: 1.3rem;
          font-weight: 400;
          line-height: 1.5rem;
          // margin-left: 0.5rem;
          // margin-top: 1.5rem;
        }

        .textContent {
          margin-top: 1rem;
          margin-bottom: 1rem;
          color: #d2d3e0;

          font-size: 1.3rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem;
        }

        .button {
          margin-top: 3rem;
        }
      }
    }
  }
}

.questionnareParentContainer {
  .questionnareContainer {
    width: 100%;
    min-width: 22rem;
    // height: 6.2rem;
    border-radius: 8px;
    border: 1px solid #2c2d3c;
    background: #20212e;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    // @media (min-width: 769px) and (max-width: 992px) {
    //   min-width: 24rem;
    // }
    // @media (min-width: 992px) and (max-width: 1200px) {
    //   min-width: 40rem;
    // }
    @media (max-width: 575px) {
      min-width: 28rem;
    }
    .questionnareTitle {
      color: #d2d3e0;
      margin-top: 0.8rem;
      margin-left: 1.2rem;

      font-size: 1.3rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2.2rem;
    }
    .optionsContainer {
      width: 30rem;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 1rem;
      margin-left: 1.2rem;
      margin-bottom: 1.2rem;
      .optionItemsContainer {
        display: flex;
        gap: 0.4rem;
        .optionTick {
          margin-top: 0.3rem;
        }
        .optionItem {
          color: #d2d3e0;
          text-transform: capitalize;
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 400;
          line-height: 2.2rem;
        }
      }
    }
  }
}
