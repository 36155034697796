.button {
  display: flex;
  width: 100%;
  height: 2.8rem;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #ffffff;

  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 115.385% */
}
