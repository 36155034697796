table,
th {
  border-collapse: collapse;
  white-space: nowrap;
}

td {
  border-collapse: collapse;
  white-space: nowrap;
}

table tr td:nth-child(n):last-child {
  padding-right: 5rem;
}

.global {
  width: 100%;
  margin-top: 2rem;
  margin-left: 3rem;
  margin-right: 2.4rem;

  .patientsHeaading {
    margin-bottom: 0.8rem;
    font-weight: 700;
    font-size: 16px;
  }
  .searchBarContainer {
    border-bottom: 0.05rem solid #212234;
  }

  .tableDiv {
    .operatorUserHeader {
      color: #d2d3e0;

      font-size: 1.6rem;
      font-style: normal;
      line-height: 2.2rem;
      padding-left: 0.5rem;
      padding-bottom: 0.8rem;
    }

    .operatorUserHeader {
      color: #d2d3e0;

      font-size: 1.6rem;
      font-style: normal;
      // font-weight: 600;
      line-height: 2.2rem;
      // padding-top: 2rem;
      padding-left: 0.5rem;
      padding-bottom: 0.8rem;
    }

    .overallContainer {
      background-color: #20212e;
      width: 95%;
      @media (max-width: 1024px) {
        width: 93%;
      }

      border-radius: 0.8rem;
      .buttonContainer {
        display: flex;
        flex-direction: column;
        align-items: end;
        height: 5.6rem;
        width: 100%;
        padding-top: 1.4rem;
        padding-bottom: 1.4rem;
        border-bottom: 0.05rem solid #2c2d3c;
        background-color: #20212e;
        border-top-left-radius: 0.8rem;
        border-top-right-radius: 0.8rem;

        .tableButton {
          margin-right: 1.4rem;
        }
      }

      // chrome
      .entireDiv:hover::-webkit-scrollbar {
        width: 0.6rem;
        height: 0.6rem;
      }
      .entireDiv::-webkit-scrollbar {
        width: 0rem;
        height: 0rem;
      }
      .entireDiv::-webkit-scrollbar-thumb {
        background-color: #858699;
        border-radius: 0.4rem;
      }

      .entireDiv::-webkit-scrollbar-track {
        background-color: #20212e;
        border-radius: 0.4rem;
      }

      .entireDiv::-webkit-scrollbar-corner {
        background-color: #20212e;
      }
      .entireDiv:window-inactive::-webkit-scrollbar {
        width: 0rem;
        height: 0rem;
      }

      .entireDiv {
        background-color: #20212e;
        overflow-y: scroll;
        width: 100%;
        height: 78vh;

        @media (min-height: 590px) and (max-height: 900px) {
          height: 74vh;
        }
        @media (max-height: 690px) {
          height: 65vh;
        }

        .tableContent {
          background-color: #20212e;
          width: 100%;
          .entireTable {
            border-radius: 0.8rem;
            background-color: #20212e;
            width: 100%;

            .tableHeader {
              width: 100%;
              position: sticky;
              top: 0;
              text-align: left;
              height: 2rem;
              color: #d2d3e0;
              font-weight: 600;
              font-size: 1.3rem;
              font-style: normal;
              line-height: 3rem;
              background-color: #20212e;
              border-bottom: 0.1rem solid #2d2c3c;
            }

            .loadingStatus {
              width: 78vw;
              height: 79vh;
            }

            .tableBody {
              width: 100%;
              .tableRow {
                width: 100%;
                border-radius: 0.8rem;
                .tableRowCell {
                  cursor: pointer;
                  color: #d2d3e0;
                  font-size: 1.3rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 2rem;
                  padding-top: 0.8rem;
                  padding-bottom: 0.8rem;
                  border-bottom: 0.05rem solid #2c2d3c;
                  // text-transform: capitalize;
                }
                .tableRowCell:not(:nth-child(6)) {
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }

      .paginationPosition {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        padding: 0.8rem 0rem;
        border-bottom-left-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
        border-bottom: 0.05rem solid #2c2d3c;
        background-color: #20212e;

        border-top: 0.05rem solid #2c2d3c;
        .paginationContainer {
          display: flex;
          list-style-type: none;
          background-color: #20212e;
          width: auto;
          margin-right: 1.4rem;

          .paginationItem {
            padding: 0 1rem;
            height: 3rem;
            margin: auto 0.4rem;
            color: #d2d3e0;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;
            letter-spacing: 0.005rem;
            border-radius: 1.7rem;
            line-height: 1.4rem;
            font-size: 1.2rem;
            min-width: 3.2rem;
            background-color: #2c2d3c;

            &.currentPageItem {
              background-color: #007f7c;
            }

            &.dots {
              background-color: transparent;
              margin: 0 0.4rem 0 0.4rem;
              color: #d2d3e0;
              font-size: 1.6rem;
              line-height: 2rem;
              font-weight: 400;
            }

            &.dots:hover {
              background-color: transparent;
              cursor: default;
            }
            &:hover {
              cursor: pointer;
            }

            &.selected {
              background-color: #007f7c;
            }

            .arrow {
              &::before {
                position: relative;
                content: '';
                display: inline-block;
                width: 0.4em;
                height: 0.4em;
                border-right: 0.12em solid #d2d3e0;
                border-top: 0.12em solid #d2d3e0;
              }

              &.left {
                transform: rotate(-135deg) translate(-50%);
              }

              &.right {
                transform: rotate(45deg);
              }
            }
          }
        }
      }
    }
  }

  .sideBar {
    @extend .tableDiv;
    width: 100%;

    .overallContainer {
      width: calc(100% - 27%);

      @media (min-width: 1700px) and (max-width: 1850px) {
        width: calc(100% - 28%);
      }

      @media (min-width: 1550px) and (max-width: 1700px) {
        width: calc(100% - 31%);
      }

      @media (min-width: 1400px) and (max-width: 1550px) {
        width: calc(100% - 36%);
      }

      @media (min-width: 1300px) and (max-width: 1400px) {
        width: calc(100% - 41%);
      }

      @media (min-width: 1000px) and (max-width: 1300px) {
        width: calc(100% - 45%);
      }
      @media (min-width: 850px) and (max-width: 1000px) {
        width: calc(100% - 53%);
      }
      @media (max-width: 850px) {
        width: calc(100% - 60%);
      }

      .loadingStatus {
        width: 100%;
      }
    }
  }
}
