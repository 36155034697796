.label {
  color: #d2d3e0;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 2.2rem;
  margin-bottom: 0.4rem;
  // width: 50rem;
}
