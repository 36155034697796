.errorComponentContainer {
  margin: 6% 3% 6% 3%;
  width: 80vw;
  height: 22rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  border: 0.05rem solid #2c2d3c;
  background: #20212e;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1);

  .errorComponent {
    text-align: center;
    .errorSign {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -2rem;
      margin-left: 13.5rem;
      width: 2.4rem;
      height: 2.4rem;

      .errorText {
        color: #f00;

        font-size: 1.6rem;
        font-weight: 600;
        line-height: 1.5rem;
        margin-left: 0.8rem;
      }
    }

    .textContent {
      margin-top: 2rem;
      color: #d2d3e0;

      font-size: 1.3rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }

    .button {
      margin-top: 4rem;
    }
  }
}
