table,
th {
  border-collapse: collapse;
  padding-left: 6rem;
  // width: 1%;
  // padding-left: 3rem;
  // padding-right: 3rem;
  white-space: nowrap;
  // table-layout: auto;
  // table-layout: fixed;
}

td {
  border-collapse: collapse;
  padding-left: 6rem;
  // width: 1%;
  // min-width: 4rem;
  // max-width: 7rem;
  // min-width: 0;
  // width: 40px;
  // max-width: 500px;
  white-space: nowrap;
  // width: auto;
  // padding-left: 3rem;
  // padding-right: 3rem;
}

.initialContainer {
  margin-top: 20vh;
  margin-left: 3vw;
  width: 80vw;
  height: 20.4rem;
  background: #2c2d3c;
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overallContainer {
  background-color: #20212e;
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;

  .headerContainer {
    display: flex;
    justify-content: flex-end;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    border-bottom: 0.05rem solid #2c2d3c;

    .searchBar {
      margin-left: 5.5rem;
      width: 280px;
      height: 38px;
      font-size: 14px;
      background-color: #191a23;
      border: 1px solid #2c2d3c;
      border-radius: 4px;
    }
  }
  .entireDiv:hover::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
  }
  .entireDiv::-webkit-scrollbar {
    width: 0rem;
    height: 0rem;
  }
  .entireDiv::-webkit-scrollbar-thumb {
    background-color: #858699;
    border-radius: 0.4rem;
  }

  .entireDiv::-webkit-scrollbar-track {
    background-color: #20212e;
    border-radius: 0.4rem;
  }

  .entireDiv::-webkit-scrollbar-corner {
    background-color: #20212e;
  }
  .entireDiv:window-inactive::-webkit-scrollbar {
    width: 0rem;
    height: 0rem;
  }

  .entireDiv {
    background-color: #20212e;
    overflow-y: scroll;
    height: 90%;
    width: 100%;
    border-radius: 0.8rem;
    // width: 80vw;
    // height: 32rem;

    // &::-webkit-scrollbar {
    //   width: 0.6rem;
    //   height: 0.6rem;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background-color: #858699;
    //   border-radius: 0.4rem;
    // }

    // &::-webkit-scrollbar-track {
    //   background-color: #20212e;
    //   border-radius: 0.4rem;
    // }

    // &::-webkit-scrollbar-corner {
    //   background-color: #20212e;
    // }

    .tableContent {
      background-color: #20212e;
      height: 100%;
      width: 100%;
      .entireTable {
        border-radius: 0.8rem;
        background-color: #20212e;
        width: 100%;
        // height: 100%;

        .tableHeader {
          width: 100%;
          position: sticky;
          top: 0;
          text-align: left;
          height: 4rem;
          color: #d2d3e0;
          // font-weight: 700;
          font-size: 1.3rem;
          font-style: normal;
          line-height: 3rem;
          background-color: #20212e;
          border-bottom: 0.1rem solid #2d2c3c;
          .theader {
            .thcell {
              font-weight: 700;
            }
          }
        }

        .loadingStatus {
          width: 100%;
          height: 100%;
        }

        .noUserFound {
          position: sticky;
          left: 40%;
          top: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          color: #d2d3e0;
          font-weight: 600;
          margin-top: 5rem;
        }

        .tableBody {
          .tableRow {
            border-radius: 0.8rem;

            .tableRowCell {
              // width: auto;
              cursor: pointer;
              height: 2rem;
              color: #d2d3e0;
              font-size: 1.3rem;
              font-style: normal;
              font-weight: 400;
              line-height: 2rem;
              padding-top: 0.8rem;
              padding-bottom: 0.8rem;
              // padding-left: 3rem;
              // padding-right: 3rem;
              border-bottom: 0.05rem solid #2c2d3c;
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }

  .paginationPosition {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding: 0.8rem 0rem;
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
    border-top: 0.05rem solid #2c2d3c;
    background-color: #20212e;

    // .paginationContainer {
    //   display: flex;
    //   list-style-type: none;
    //   background-color: #20212e;
    //   width: auto;
    //   margin-right: 1.4rem;

    //   .paginationItem {
    //     padding: 0 1rem;
    //     height: 3rem;
    //     margin: auto 0.4rem;
    //     color: #d2d3e0;
    //     display: flex;
    //     box-sizing: border-box;
    //     align-items: center;
    //     justify-content: center;
    //     letter-spacing: 0.005rem;
    //     border-radius: 1.7rem;
    //     line-height: 1.4rem;
    //     font-size: 1.2rem;
    //     min-width: 3.2rem;
    //     background-color: #2c2d3c;

    //     &.currentPageItem {
    //       background-color: #007f7c;
    //     }

    //     &.dots {
    //       background-color: transparent;
    //       margin: 0 0.4rem 0 0.4rem;
    //       color: #d2d3e0;
    //       font-size: 1.6rem;
    //       line-height: 2rem;
    //       font-weight: 400;
    //     }

    //     &.dots:hover {
    //       background-color: transparent;
    //       cursor: default;
    //     }
    //     &:hover {
    //       cursor: pointer;
    //     }

    //     &.selected {
    //       background-color: #007f7c;
    //     }

    //     .arrow {
    //       &::before {
    //         position: relative;
    //         content: '';
    //         display: inline-block;
    //         width: 0.4em;
    //         height: 0.4em;
    //         border-right: 0.12em solid #d2d3e0;
    //         border-top: 0.12em solid #d2d3e0;
    //       }

    //       &.left {
    //         transform: rotate(-135deg) translate(-50%);
    //       }

    //       &.right {
    //         transform: rotate(45deg);
    //       }
    //     }
    //   }
    // }
  }
}

.paginationItem {
  padding: 0 1rem;
  height: 3rem;
  margin: auto 0.4rem;
  color: #d2d3e0;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.005rem;
  border-radius: 1.7rem;
  line-height: 1.4rem;
  font-size: 1.2rem;
  min-width: 3.2rem;
  background-color: #2c2d3c;
}
