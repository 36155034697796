.profileContainer {
  display: flex;
  align-items: center;
  padding-left: 2rem;
  width: 52vw;
  min-height: 5.8rem;
  border-radius: 4px;
  border: 1px solid #2c2d3c;
  background: #20212e;
  color: #d2d3e0;
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

  .profileLeftDiv {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.4rem;
    margin-right: 2rem;
    border-right: 0.15rem solid #2c2d3c;
    .profileImg {
      margin-right: 0.8rem;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      //  background-color: #ababb2;
      line-height: 10px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
    .profileImgTxt {
      margin-right: 0.8rem;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: black;
      font-weight: 600;
      background-color: #ababb2;
      line-height: 10px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
    .profileName {
      width: 18rem;
      margin-right: 2rem;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 2rem; /* 123.077% */
      text-transform: capitalize;
    }
    .profileAge {
      color: #d2d3e0;
      width: 3rem;
      margin-right: 2rem;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 123.077% */
    }
  }

  :last-child {
    border-right: none;
  }
}
