@import 'VariableColors.module.scss';

.button {
  min-width: 9.2rem;
  width: fit-content;
  height: 2.8rem;
  border-radius: 0.4rem;
  background: $primary-color;
  cursor: pointer;
  // padding-left: 1rem;
  // padding-right: 1rem;
  border: 0.1rem solid #313248;
  color: #fff;

  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}
