.wrapper {
  div {
    .activeContainer {
      display: flex;
      justify-content: end;
      .isActiveContainer {
        display: flex;
        gap: 0.8rem;
        .isActive {
          margin-top: 0.6rem;
        }
      }
    }
    .dropdownMenu {
      background-color: #191a23;
      width: 20rem;
      text-transform: capitalize;
      border: '0.5px solid #2C2D3C';
    }
    .dropdownsss {
      text-transform: capitalize;
    }
    .footer {
      display: flex;
      flex-direction: row;
      gap: 0.8rem;
      margin-top: 1.6rem;
      .button {
        height: 2.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 9.2rem;
      }
    }
  }
}
.closeContainer {
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 4px;
  background: #20212e;
  background-image: url(../../../images/cross.svg);
  background-repeat: no-repeat;
  background-position: center;
}
