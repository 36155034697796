.wrapper {
  div {
    .dropdownMenu {
      background-color: #191a23;
      width: 20rem;
      text-transform: capitalize;
      border: '0.5px solid #2C2D3C';
    }
    .dropdownsss {
      text-transform: capitalize;
    }
    .footer {
      display: flex;
      flex-direction: row;
      gap: 0.8rem;
      margin-top: 1.6rem;
      .button {
        height: 2.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 9.2rem;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 0rem;
  height: 0rem;
}
