// @import "./reset";
// @import "./common/variables";
// @import "./common/colours";
@import './font-definitions';

html {
  font-size: 62.5%;
  background-color: #191a23;
  /* overflow-x: hidden; */
}

body {
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

/* p {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  text-indent: 0;
} */

img,
video {
  border: 0;
  height: auto;
  max-width: 100%;
}

/* table {
  border-collapse: collapse;
  border-spacing: 0;
} */

input,
button,
select,
textarea {
  border: none;
  outline: none;
}

a,
button,
input,
textarea {
  -webkit-tap-highlight-color: transparent;
  font-family: 'WixMadeforDisplay', 'HelveticaNeue', 'Trispace', Helvetica, Arial, sans-serif;
}

body {
  margin: 0;
  font-family: 'WixMadeforDisplay', 'HelveticaNeue', 'Trispace', Helvetica, Arial, sans-serif;
  //  color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: soursce-code-pro, Menlo, Monaco, Consolas, Trispace, 'WixMadeforDisplay', 'Courier New', monospace;
}

button {
  cursor: pointer;
}

// Hiding number picker for input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
