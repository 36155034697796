.global {
  width: 100%;
  height: 100vh;

  .comingSoonDiv {
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2.4rem;

    .overallContainer {
      background-color: #20212e;
      width: 80vw;
      height: 70vh;

      border-radius: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .textContent {
        color: #d2d3e0;

        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }
}
