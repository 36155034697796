.toastMsg {
  // color: #fffeff;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  // margin-bottom: 0.5rem;
}

.toastMsg::first-word {
  font-weight: bolder;
  font-size: 4rem;
  color: green;
}
