.scheduleContainer {
  background-color: #20212e;
  padding: 1.2rem 2rem;
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;

  .editScheduleBtn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 1rem;
    // margin-right: 4rem;
    // padding-right: 4rem;
  }
  .availabilityRow {
    display: flex;
    gap: 1.2rem;
    .daysWrapper {
      display: flex;
      margin-top: 0.5rem;
      @media (max-width: 770px) {
        margin-top: 1.44rem;
      }
      justify-content: center;
      align-items: center;
      width: 10rem;
      height: 4rem;
      border-radius: 4px;
      border: 1px solid #2d2c3c;
      .daysHeader {
        color: #d2d3e0;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
    .availabilityDays {
      .availabilitySet {
        gap: 1.7rem;

        .timeDropdown {
          display: flex;
          margin-top: 0.5rem;
          @media (max-width: 770px) {
            margin-top: 1rem;
          }
          gap: 1rem;
          .timeDropdownContainer {
            display: flex;
            gap: 0.8rem;
            @media (max-width: 770px) {
              flex-direction: column;
            }
            .startTimeContainer {
              display: flex;
              min-width: 20rem;
            }
            .endTimeContainer {
              display: flex;
              min-width: 20rem;
            }
          }
        }
      }
    }
    .addBtnParent {
      margin-top: 0.4rem;
    }
    .radioToggleButtonParent {
      margin-top: 0.2rem;
    }
  }
}
