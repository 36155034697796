.content {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0rem;
    height: 0rem;
  }
}
.editDoctorHeader {
  color: #d2d3e0;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.8rem;
  margin-left: 0.5rem;
  margin-bottom: 1.2rem;
}
.editContainer {
  background-color: #20212e;
  padding: 1.2rem 2rem;
  width: 100%;
  border-radius: 0.8rem;
}

.activeContainer {
  display: flex;
  justify-content: end;
  .isActiveContainer {
    display: flex;
    gap: 0.8rem;
    .isActive {
      margin-top: 0.6rem;
    }
  }
}

.rowOne {
  display: flex;
  gap: 2rem;
}

.rowTwo {
  display: flex;
  gap: 2rem;
}

.rowThree {
  display: flex;
  gap: 2rem;
}

.buttonContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;

  .footer {
    display: flex;
    gap: 0.8rem;
    margin-top: 4rem;
    .button {
      height: 2.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 9.2rem;
    }
  }
}

.availAndEditSchedule {
  display: flex;
  justify-content: space-between;
  .editScheduleBtn {
    margin-top: 1rem;
  }
  .availabilityHeader {
    color: #d2d3e0;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    margin-top: 2rem;
    margin-left: 0.5rem;
    margin-bottom: 1rem;
  }
}

.availabilityBtnContainer {
  width: 100%;
  margin-top: 3rem;
  .footer {
    display: flex;
    gap: 0.8rem;
    .button {
      height: 2.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 9.2rem;
    }
  }
}

// ViewAvailability
.scheduleContainer {
  background-color: #20212e;
  padding: 1.2rem 2rem;
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  .availabilityRow {
    display: flex;
    gap: 1.2rem;
    .daysWrapper {
      display: flex;
      margin-top: 0.5rem;
      @media (max-width: 770px) {
        margin-top: 1.44rem;
      }
      justify-content: center;
      align-items: center;
      width: 10rem;
      height: 4rem;
      border-radius: 4px;
      border: 1px solid #2d2c3c;
      .daysHeader {
        color: #d2d3e0;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
    .availabilityDays {
      .availabilitySet {
        gap: 1.7rem;

        .timeDropdown {
          display: flex;
          margin-top: 0.5rem;
          @media (max-width: 770px) {
            margin-top: 1rem;
          }
          gap: 1rem;
          .timeDropdownContainer {
            display: flex;
            gap: 0.8rem;
            @media (max-width: 770px) {
              flex-direction: column;
            }
            .startTimeContainer {
              display: flex;
            }
            .endTimeContainer {
              display: flex;
            }
          }
        }
      }
    }
    .addBtnParent {
      margin-top: 0.4rem;
    }
    .radioToggleButtonParent {
      margin-top: 0.2rem;
    }
  }
}
