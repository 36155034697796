table,
th {
  border-collapse: collapse;
  padding-left: 6rem;
  // width: 1%;
  // padding-left: 3rem;
  // padding-right: 3rem;
  white-space: nowrap;
  // table-layout: auto;
  // table-layout: fixed;
}

td {
  border-collapse: collapse;
  padding-left: 6rem;
  // width: 1%;
  // min-width: 4rem;
  // max-width: 7rem;
  // min-width: 0;
  // width: 40px;
  // max-width: 500px;
  white-space: nowrap;
  // width: auto;
  // padding-left: 3rem;
  // padding-right: 3rem;
}

// p {
//   width: fit-content;
// }

// .firstName {
//   width: 5rem;
// }
// .lastName {
//   width: 6rem;
// }
// .phone {
//   width: 5rem;
// }

.global {
  width: 100%;
  height: 70vh;
  // overflow: scroll;

  // &::-webkit-scrollbar {
  //   display: none;
  // }
  // border-top: 0.1rem solid #2d2c3c;
  // border-top: 0.1rem solid #2d2c3c;
  .searchBarContainer {
    border-bottom: 0.05rem solid #212234;
  }

  .tableDiv {
    padding-top: 0.8rem;
    padding-bottom: 3rem;
    padding-left: 1.4rem;
    @media (max-width: 1024px) {
      width: 80vw;
    }

    .overallContainer {
      background-color: #20212e;
      width: 80vw;
      @media (max-width: 1024px) {
        width: 80vw;
      }

      border-radius: 0.8rem;
      .buttonContainer {
        display: flex;
        flex-direction: column;
        align-items: end;
        width: 80vw;
        @media (max-width: 1024px) {
          width: 80vw;
        }
        padding-top: 1.4rem;
        padding-bottom: 1.4rem;
        border-bottom: 0.05rem solid #2c2d3c;
        background-color: #20212e;
        border-top-left-radius: 0.8rem;
        border-top-right-radius: 0.8rem;

        .tableButton {
          margin-right: 1.4rem;
        }
      }

      // .show::-webkit-scrollbar {
      //   width: 0.6rem;
      //   height: 0.6rem;
      // }
      // .hide::-webkit-scrollbar {
      //   width: 0;
      //   height: 0;
      // }
      // .simplebar-scrollbar::before {
      //   background: red !important;
      // }

      // chrome
      .entireDiv:hover::-webkit-scrollbar {
        width: 0.6rem;
        height: 0.6rem;
      }
      .entireDiv::-webkit-scrollbar {
        width: 0rem;
        height: 0rem;
      }
      .entireDiv::-webkit-scrollbar-thumb {
        background-color: #858699;
        border-radius: 0.4rem;
      }

      .entireDiv::-webkit-scrollbar-track {
        background-color: #20212e;
        border-radius: 0.4rem;
      }

      .entireDiv::-webkit-scrollbar-corner {
        background-color: #20212e;
      }
      .entireDiv::-webkit-scrollbar {
        // width: 0.6rem;
        // height: 0.6rem;
      }
      .entireDiv:window-inactive::-webkit-scrollbar {
        width: 0rem;
        height: 0rem;
      }

      // firefox
      // .entireDiv {
      //   scrollbar-width: none;
      //   overflow: auto;
      // }

      // .entireDiv:hover {
      //   scrollbar-width: thin;
      //   scrollbar-color: #858699 #20212e;
      // }

      .entireDiv {
        background-color: #20212e;
        overflow-y: scroll;
        width: 80vw;
        @media (max-width: 1024px) {
          width: 80vw;
        }
        height: 32rem;

        // &::-webkit-scrollbar {
        //   width: 0.6rem;
        //   height: 0.6rem;
        // }

        // &::-webkit-scrollbar-thumb {
        //   background-color: #858699;
        //   border-radius: 0.4rem;
        // }

        // &::-webkit-scrollbar-track {
        //   background-color: #20212e;
        //   border-radius: 0.4rem;
        // }

        // &::-webkit-scrollbar-corner {
        //   background-color: #20212e;
        // }
        // .simplebarScrollbar:before {
        //   background: green;
        // }
        // .show::-webkit-scrollbar {
        //   width: 0.6rem;
        //   height: 0.6rem;
        //   // scrollbar-gutter: none;
        // }
        // .hide::-webkit-scrollbar {
        //   width: 0;
        //   height: 0;
        // }
        .tableContent {
          background-color: #20212e;
          height: 31rem;
          width: 79.5vw;
          @media (max-width: 1024px) {
            width: 90vw;
          }
          .entireTable {
            border-radius: 0.8rem;
            background-color: #20212e;
            // height: 31rem;
            width: 79.5vw;
            @media (max-width: 1024px) {
              width: 90vw;
            }

            .tableHeader {
              width: 79.5vw;
              position: sticky;
              top: 0;
              text-align: left;
              // width: 4rem;
              height: 2rem;
              color: #d2d3e0;
              font-weight: 600;
              font-size: 1.3rem;
              font-style: normal;
              line-height: 3rem;
              background-color: #20212e;
              border-bottom: 0.1rem solid #2d2c3c;
            }

            .loadingStatus {
              width: 78vw;
              height: 79vh;
            }

            .tableBody {
              // height: 31rem;
              width: 79.5vw;
              .tableRow {
                width: 79.5vw;
                border-radius: 0.8rem;

                .tableRowCell {
                  // width: auto;
                  cursor: pointer;
                  height: 2rem;
                  color: #d2d3e0;
                  font-size: 1.3rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 2rem;
                  padding-top: 0.8rem;
                  padding-bottom: 0.8rem;
                  // padding-left: 3rem;
                  // padding-right: 3rem;
                  border-bottom: 0.05rem solid #2c2d3c;
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }

      .paginationPosition {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 80vw;
        @media (max-width: 1024px) {
          width: 79.5vw;
        }
        padding: 0.8rem 0rem;
        border-bottom-left-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
        border-bottom: 0.05rem solid #2c2d3c;
        background-color: #20212e;

        .paginationContainer {
          display: flex;
          list-style-type: none;
          background-color: #20212e;
          width: auto;
          margin-right: 1.4rem;

          .paginationItem {
            padding: 0 1rem;
            height: 3rem;
            margin: auto 0.4rem;
            color: #d2d3e0;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;
            letter-spacing: 0.005rem;
            border-radius: 1.7rem;
            line-height: 1.4rem;
            font-size: 1.2rem;
            min-width: 3.2rem;
            background-color: #2c2d3c;

            &.currentPageItem {
              background-color: #007f7c;
            }

            &.dots {
              background-color: transparent;
              margin: 0 0.4rem 0 0.4rem;
              color: #d2d3e0;
              font-size: 1.6rem;
              line-height: 2rem;
              font-weight: 400;
            }

            &.dots:hover {
              background-color: transparent;
              cursor: default;
            }
            &:hover {
              cursor: pointer;
            }

            &.selected {
              background-color: #007f7c;
            }

            .arrow {
              &::before {
                position: relative;
                content: '';
                display: inline-block;
                width: 0.4em;
                height: 0.4em;
                border-right: 0.12em solid #d2d3e0;
                border-top: 0.12em solid #d2d3e0;
              }

              &.left {
                transform: rotate(-135deg) translate(-50%);
              }

              &.right {
                transform: rotate(45deg);
              }
            }
          }
        }
      }
    }
  }
  .sideBar {
    @extend .tableDiv;
    width: calc(79.5vw - 37rem);

    @media (max-width: 1024px) {
      width: calc(79.5vw - 28rem);
    }
    .overallContainer {
      width: calc(79.5vw - 37rem);

      @media (max-width: 1024px) {
        width: calc(79.5vw - 28rem);
      }
      .buttonContainer {
        width: calc(80vw - 37rem);

        @media (max-width: 1024px) {
          width: calc(80vw - 28rem);
        }
      }

      .entireDiv {
        width: calc(80vw - 37rem);

        @media (max-width: 1024px) {
          width: calc(80vw - 28rem);
        }
        .tableContent {
          width: calc(79.5vw - 37rem);

          @media (max-width: 1024px) {
            width: calc(79.5vw - 28rem);
          }
          .entireTable {
            width: calc(79.5vw - 37rem);

            @media (max-width: 1024px) {
              width: calc(79.5vw - 28rem);
            }
            .tableHeader {
              width: calc(79.5vw - 37rem);

              @media (max-width: 1024px) {
                width: calc(79.5vw - 28rem);
              }
            }
            .tableBody {
              width: calc(79.5vw - 37rem);

              @media (max-width: 1024px) {
                width: calc(79.5vw - 28rem);
              }
              .tableRow {
                width: calc(79.5vw - 37rem);

                @media (max-width: 1024px) {
                  width: calc(79.5vw - 28rem);
                }
              }
            }
          }
        }
      }

      .paginationPosition {
        width: calc(80vw - 37rem);

        @media (max-width: 1024px) {
          width: calc(80vw - 28rem);
        }
      }

      .loadingStatus {
        width: calc(79.5vw - 37rem);

        @media (max-width: 1024px) {
          width: calc(79.5vw - 28rem);
        }
      }
    }
  }

  // .sidebarOnInitialTableComponent {
  //   @extend .initialTableComponent;
  //   width: calc(80vw - 35rem);
  //
  // }
}
