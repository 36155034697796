.container {
  width: 36.5rem;
  height: 95vh;
  box-sizing: border-box;
  border: 0.1px solid #212234;
  background: #191a23;
  position: absolute;
  top: 5.6rem;
  right: -37rem;
  // @media (max-height: 1000px) {
  //   height: 93.8vh;
  // }
  // @media (max-height: 900px) {
  //   height: 90.5vh;
  // }
  // @media (min-height: 1000px) and (max-height: 2000px) {
  //   height: 95vh;
  // }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    height: 7.6rem;
    border-bottom: 0.1rem solid #212234;
    margin-bottom: 2.4rem;
    span {
      color: #d2d3e0;

      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.6rem;
    }
    .crossSvg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 0.4rem;
      background: #20212e;
      cursor: pointer;
    }
  }
  .formContainer {
    box-sizing: border-box;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    @media (max-height: 750px) {
      overflow-y: auto;
      height: 70vh;
      &::-webkit-scrollbar {
        width: 0rem;
        height: 0rem;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #858699;
        // border-radius: 0.4rem;
      }

      &::-webkit-scrollbar-track {
        background-color: #20212e;
        // border-radius: 0.4rem;
      }

      &::-webkit-scrollbar-corner {
        background-color: #20212e;
      }
    }

    .formDetails {
      // @media (max-height: 750px) {
      //   overflow-y: scroll;
      //   &::-webkit-scrollbar {
      //     width: 0rem;
      //     height: 0rem;
      //   }
      //   &::-webkit-scrollbar-thumb {
      //     background-color: #858699;
      //     // border-radius: 0.4rem;
      //   }

      //   &::-webkit-scrollbar-track {
      //     background-color: #20212e;
      //     // border-radius: 0.4rem;
      //   }

      //   &::-webkit-scrollbar-corner {
      //     background-color: #20212e;
      //   }
      // }
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      .phoneInputLabel {
        color: #d2d3e0;

        font-size: 1.3rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.2rem;
        margin-bottom: 0.4rem;
      }
    }
    .buttonCtn {
      display: flex;
      flex-direction: row;
      gap: 1.6rem;
      margin-top: 3rem;
    }
  }
}
